import axios from 'axios';
import { urlBase, userCredentials } from './base';

export const index = async () => {
  const { token } = userCredentials();

  const url = `${urlBase}?t=${token}`;

  const response = await axios.get(url);

  return response.data;
};

export const orderset = async order => {
  const { token } = userCredentials();

  const url = `${urlBase}/orderset/?t=${token}`;

  const response = await axios.post(url, order);

  return response.data;
};

export const ordersstatus = async orders => {
  const { token } = userCredentials();

  const url = `${urlBase}/ordersstatus/?t=${token}`;

  const response = await axios.post(url, orders);

  return response.data;
};

export const pix = async order => {
  const { token } = userCredentials();

  const url = `${urlBase}/pix/${order}/?t=${token}`;

  const response = await axios.get(url);

  return response.data;
};
