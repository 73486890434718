import React from 'react';

import { Container } from './styles';

function Payment({ order }) {
  const { pix } = order;

  return (
    <Container>
      {pix === 'S' ? (
        <>
          <span>Pagamento antecipado</span>
          <span>Pix</span>
        </>
      ) : (
        <>
          <span>Pagamento</span>
          <span>?</span>
        </>
      )}
    </Container>
  );
}

export { Payment };
