import { combineReducers } from 'redux';

import data from './data/reducer';
import item from './item/reducer';
import order from './order/reducer';

export default combineReducers({
  data,
  item,
  order,
});
