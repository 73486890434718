import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AccessTime, Call, PinDrop } from '@material-ui/icons';

import MaskService from 'react-masked-text/src/mask-service';
import useStyles from './styles';

export default function Header(props) {
  const classes = useStyles(props);

  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [journey, setJourney] = useState(null);

  const { company, number } = useSelector(state => state.data);

  useEffect(() => {
    setName(company.name);
    const cel = MaskService.toMask('cel-phone', `${company.phone}`);
    setPhone(cel);
    setJourney(company.journey);
  }, [company]);

  return (
    <div className={classes.root}>
      <div className={classes.number}>
        <PinDrop style={{ fontSize: '2rem' }} />
        <span>{number}</span>
      </div>
      <div className={classes.cover} />
      <div className={classes.container}>
        <span className={classes.title}>{name}</span>
        <div className={classes.info}>
          <div className={classes.field}>
            <Call style={{ fontSize: '1.3rem' }} />
            <span className={classes.text}>{phone}</span>
          </div>
          <div className={classes.field}>
            <AccessTime style={{ fontSize: '1.3rem' }} />
            <span className={classes.text}>{journey}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
