export const ActionTypes = Object.freeze({
  LOAD: '@data/LOAD',
  SET_CLIENT: '@data/SET_CLIENT',
  LOADING: '@data/LOADING',
});

export function dataLoad({ token, number, company, categories }) {
  return {
    type: ActionTypes.LOAD,
    payload: { token, number, company, categories },
  };
}

export function dataSetClient({ name, phone }) {
  return {
    type: ActionTypes.SET_CLIENT,
    payload: { client: { name, phone } },
  };
}

export function dataLoading() {
  return { type: ActionTypes.LOADING };
}
