import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import { useSnackbar } from 'notistack';

import useStyles from './styles';
import { orderCheckout } from '../../../store/modules/order/actions';

function Send() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { company, client } = useSelector(state => state.data);

  const handleConfirm = useCallback(
    async (com, cli) => {
      if (com.enabled !== 1) {
        enqueueSnackbar('Indisponível no momento', { variant: 'error' });

        return;
      }

      if (cli.name.length === 0) {
        enqueueSnackbar('Qual o seu nome?', { variant: 'error' });

        return;
      }

      if (cli.phone.length <= 9) {
        enqueueSnackbar('Seu telefone deve ser preenchido corretamente', {
          variant: 'error',
        });

        return;
      }

      dispatch(orderCheckout());
    },
    [dispatch, enqueueSnackbar]
  );

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => handleConfirm(company, client)}
      >
        pedir
      </Button>
    </div>
  );
}

export default Send;
