import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Drawer, Button } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import {
  orderCheckout,
  orderConfirmed,
} from '../../../store/modules/order/actions';
import { dataLoading } from '../../../store/modules/data/actions';
import { orderset } from '../../../services/application';

import { Container, Actions } from './styles';
import Totals from '../Totals';

const removerCaracteresEspeciais = string => {
  if (!string || string.length === 0) {
    return '';
  }

  const string_sem_especiais = string.replace(/[^\p{L}\p{N}\s]/gu, '');

  const string_sem_acentos = string_sem_especiais
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return string_sem_acentos.trim();
};

function Checkout() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { company, loading, client } = useSelector(state => state.data);
  const { modalCheckout, order: current } = useSelector(state => state.order);

  const handleConfirm = useCallback(
    async (pix, com, cli, cur, load) => {
      if (load) return;

      if (com.enabled !== 1) {
        enqueueSnackbar('Indisponível no momento', { variant: 'error' });

        return;
      }

      if (cli.name.length === 0) {
        enqueueSnackbar('Qual o seu nome?', { variant: 'error' });

        return;
      }

      if (cli.phone.length <= 9) {
        enqueueSnackbar('Seu telefone deve ser preenchido corretamente', {
          variant: 'error',
        });

        return;
      }

      dispatch(dataLoading());

      const order = {
        version: '1.0.0',
        number: Number(cur.number),
        deliveryfee: cur.delivery,
        client: { ...cli },
        items: cur.items.map(item => {
          const subitems = [];

          item.complements.forEach(complement => {
            complement.items
              .filter(subitem => subitem.amount >= 1)
              .forEach(subitem => {
                const { product_id, name, amount, price } = subitem;
                subitems.push({
                  product_id,
                  name,
                  amount,
                  price: complement.required === 'N' ? price : 0,
                  subtotal: complement.required === 'N' ? price * amount : 0,
                });
              });
          });

          const { product_id, name, amount, price, comment } = item;
          const subtotal = amount * price;
          const totalsubitems = subitems.reduce((a, b) => {
            return a + b.subtotal;
          }, 0);

          return {
            product_id,
            name,
            amount,
            price,
            subtotal,
            totalsubitems: amount * totalsubitems,
            total: subtotal + amount * totalsubitems,
            comment: removerCaracteresEspeciais(comment),
            subitems,
          };
        }),
        subtotal: cur.subtotal,
        total: cur.total,
        pix,
      };

      try {
        const response = await orderset(order);

        if (response && response.success) {
          dispatch(orderConfirmed(response.data));
          dispatch(dataLoading());
          return;
        }

        if (response && response.error) {
          dispatch(dataLoading());
          enqueueSnackbar(response.error_message, { variant: 'error' });
          return;
        }

        dispatch(dataLoading());
        enqueueSnackbar(
          'Houve uma falha interna e não foi possível salvar os dados do seu pedido. Tente mais tarde',
          { variant: 'error' }
        );
      } catch (error) {
        dispatch(dataLoading());

        enqueueSnackbar(
          'Falha ao tentar enviar seu pedido. Verifique sua conexão com a internet',
          { variant: 'error' }
        );
      }
    },
    [enqueueSnackbar, dispatch]
  );

  return (
    <Drawer
      anchor="bottom"
      open={modalCheckout}
      onClose={() => dispatch(orderCheckout())}
    >
      <Container>
        <Totals />
        {company.receberempix === 'S' && company.pixonline === 'S' ? (
          <Actions>
            <Button
              variant="outlined"
              onClick={() => {
                handleConfirm('N', company, client, current, loading);
              }}
            >
              pagar depois no caixa
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleConfirm('S', company, client, current, loading);
              }}
            >
              pague agora no pix
            </Button>
          </Actions>
        ) : (
          <Actions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleConfirm('N', company, client, current, loading);
              }}
            >
              confirmar pedido
            </Button>
          </Actions>
        )}
      </Container>
    </Drawer>
  );
}

export { Checkout };
