import { getClient, setClient } from '../../../infra/config/storage';
import { ActionTypes } from './actions';

export default function data(
  state = {
    loading: true,
    token: '',
    number: 0,
    company: {},
    categories: [],
    client: { name: '', phone: '' },
  },
  action
) {
  switch (action.type) {
    case ActionTypes.LOAD: {
      const { token, number, company, categories } = action.payload;
      const client = getClient();

      return {
        ...state,
        token,
        number,
        company,
        categories,
        client,
        loading: false,
      };
    }

    case ActionTypes.SET_CLIENT: {
      const { client } = action.payload;
      setClient(client);
      return { ...state, client };
    }

    case ActionTypes.LOADING: {
      return {
        ...state,
        loading: !state.loading,
      };
    }

    default:
      return state;
  }
}
