import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Drawer, Typography } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import useStyles from './styles';
import { orderSuccess } from '../../store/modules/order/actions';

export default function Success() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customer = useSelector(state => {
    const { data } = state;
    const { client } = data;
    const { name } = client;
    return name.split(' ')[0].toLowerCase();
  });

  const { modalOrderSuccess } = useSelector(state => state.order);

  const handleClose = () => {
    dispatch(orderSuccess());
  };

  return (
    <Drawer anchor="bottom" open={modalOrderSuccess} onClose={handleClose}>
      <div className={classes.root}>
        <Typography variant="h3" component="h1">
          Sucesso!
        </Typography>

        <div className={classes.icon}>
          <DoneAllIcon fontSize="large" />
        </div>

        <Typography>
          <strong style={{ textTransform: 'capitalize' }}>{customer}</strong>,
          recebemos o seu pedido!
        </Typography>

        <Typography>
          Em breve entregaremos o pedido em sua mesa. Fique a vontade para
          realizar novos pedidos.
        </Typography>

        <Typography>
          Acompanhe seu pedido na aba <strong>PEDIDOS</strong>
        </Typography>

        <Button variant="contained" onClick={handleClose}>
          OK entendi
        </Button>
      </div>
    </Drawer>
  );
}
