import React from 'react';
import { formatPrice } from '../../../../../../utils/format';

import { Container, Totals } from './styles';

function Summary({ order }) {
  const subtotal = formatPrice(order.subtotal);
  const delivery = formatPrice(order.delivery);
  const total = formatPrice(order.total);

  return (
    <Container>
      <span>Resumo dos valores</span>
      <Totals>
        <span>Subtotal</span>
        <span>{subtotal}</span>
      </Totals>
      <Totals>
        <span>Taxa</span>
        <span>{delivery}</span>
      </Totals>
      <Totals>
        <span>Total</span>
        <span>{total}</span>
      </Totals>
    </Container>
  );
}

export { Summary };
