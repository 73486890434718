export const ActionTypes = Object.freeze({
  CREATE: '@order/CREATE',
  ADD_ITEM: '@order/ADD_ITEM',
  REMOVE_ITEM: '@order/REMOVE_ITEM',
  SHOW: '@order/SHOW',
  CLOSE: '@order/CLOSE',
  CONFIRMED: '@order/CONFIRMED',
  SUCCESS: '@order/SUCCESS',
  CHECKOUT: '@order/CHECKOUT',
  CONFIRMED_PIX: '@order/CONFIRMED_PIX',
});

export function orderCreate() {
  return {
    type: ActionTypes.CREATE,
  };
}

export function orderAddItem(item) {
  return {
    type: ActionTypes.ADD_ITEM,
    payload: { item },
  };
}

export function orderRemoveItem(key) {
  return {
    type: ActionTypes.REMOVE_ITEM,
    payload: { key },
  };
}

export function orderShow() {
  return {
    type: ActionTypes.SHOW,
  };
}

export function orderClose() {
  return {
    type: ActionTypes.CLOSE,
  };
}

export function orderConfirmed({
  id,
  moment,
  pix,
  pixtxid,
  pixqrcode,
  pixexpiration,
  pixsituacao,
}) {
  return {
    type: ActionTypes.CONFIRMED,
    payload: {
      id,
      moment,
      pix,
      pixtxid,
      pixqrcode,
      pixexpiration,
      pixsituacao,
    },
  };
}

export function orderSuccess() {
  return {
    type: ActionTypes.SUCCESS,
  };
}

export function orderCheckout() {
  return {
    type: ActionTypes.CHECKOUT,
  };
}

export function orderConfirmedPix() {
  return {
    type: ActionTypes.CONFIRMED_PIX,
  };
}
