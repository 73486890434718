import React from 'react';

import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';

import { Container } from './styles';

function OrderStatus({ order }) {
  const { status } = order;
  return (
    <Container>
      {status === 'created' && (
        <>
          <DoneOutlinedIcon />
          <span>Pedido criado</span>
        </>
      )}

      {status === 'received' && (
        <>
          <DoneAllOutlinedIcon />
          <span>Pedido recebido</span>
        </>
      )}

      {status === 'accepted' && (
        <>
          <DescriptionOutlinedIcon />
          <span>Pedido aceito</span>
        </>
      )}

      {status === 'concluded' && (
        <>
          <CheckCircleOutlinedIcon />
          <span>Pedido concluido</span>
        </>
      )}

      {status === 'canceled' && (
        <>
          <BlockOutlinedIcon />
          <span>Pedido cancelado</span>
        </>
      )}
    </Container>
  );
}

export { OrderStatus };
