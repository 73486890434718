import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Header from '../../components/Header';
import Items from './Items';
import Totals from './Totals';
import Client from './Client';
import Send from './Send';
import { Checkout } from './Checkout';

import useStyles from './styles';
import { orderClose } from '../../store/modules/order/actions';

export default function Order() {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  const { modalOrder } = useSelector(state => state.order);

  const handleClose = () => {
    dispatch(orderClose());
  };

  return (
    <Dialog fullScreen={fullScreen} open={modalOrder} className={classes.root}>
      <Header handleClose={handleClose} title="Pedido" />
      <div className={classes.content}>
        <Items />
        <Totals />
        <Client />
        <Send />
        <Checkout />
      </div>
    </Dialog>
  );
}
