import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh / 2 + 60px)',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',

    padding: '20px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },

  icon: {
    width: '100px',
    height: '100px',
    borderRadius: '50px',
    backgroundColor: '#4caf50',
    color: '#fff',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
