import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '56px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'black',
    zIndex: 100,
    background: '#fff',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  button: {
    color: '#F97F11',
  },
  text: {
    fontSize: '0.875rem',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
});

export default useStyles;
