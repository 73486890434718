import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import useStyles from './styles';

function Header({ handleClose, title }) {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <IconButton className={classes.button} onClick={handleClose}>
        <ArrowBackIosIcon />
      </IconButton>
      <span className={classes.text}>{title}</span>
    </header>
  );
}

export default Header;
