import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    display: 'flex',
    flexDirection: 'column',

    '& input': {
      marginTop: '12px',
      height: '40px',
      padding: '0px 15px',
      border: '1px solid #e0e0e0',
      borderRadius: '.5rem',
    },

    marginBottom: '30px',
  },
  fone: {
    display: 'flex',
    flexDirection: 'column',

    '& input': {
      marginTop: '12px',
      height: '40px',
      padding: '0px 15px',
      border: '1px solid #e0e0e0',
      borderRadius: '.5rem',
    },
  },
});

export default useStyles;
