import React from 'react';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';

import { PageHeader } from '../../../../components/PageHeader';
import { Header } from './components/Header';
import { Items } from './components/Items';
import { Summary } from './components/Summary';
import { Payment } from './components/Payment';
import { AddToCart } from '../../../../components/AddToCart';

import { Container, Content } from './styles';

function Detail({ company, background, order, onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog fullScreen={fullScreen} open={!!order}>
      <Container>
        <PageHeader handleClose={onClose} title="Detalhes do Pedido" />
        <Content>
          {order && (
            <>
              <Header company={company} background={background} order={order} />
              <Items order={order} />
              <Summary order={order} />
              <Payment order={order} />
              <AddToCart order={order} />
            </>
          )}
        </Content>
      </Container>
    </Dialog>
  );
}

export { Detail };
