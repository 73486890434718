import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 1rem 0;

  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  height: 50px;
  margin: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #43a047;
  color: #fff;

  &:hover {
    background-color: #388e3c;
  }

  border-radius: 0.5rem;

  border: none;
  outline: none;
  cursor: pointer;

  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
`;

export const Actions = styled.div`
  width: 100%;
  padding: 1rem 1rem 0 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  & button {
    padding: 0.75rem;
    border-radius: 0.5rem;
  }

  & button:last-child {
    background-color: #43a047;
  }
`;
