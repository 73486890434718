const types = Object.freeze({
  CREDENTIALS: '@shop:credentials:1.0.0',
  ORDER: '@shop:order:1.0.0',
  CLIENT: '@shop:client:1.0.0',
  ORDERS: '@shop:orders:1.0.0',
});

const defaultOrder = {
  number: 0,
  items: [],
  subtotal: 0,
  delivery: 0,
  total: 0,
  modality: 2,
};

const defaultClient = { name: '', phone: '' };

class Credentials {
  constructor() {
    if (Credentials.instance) {
      return Credentials.instance;
    }

    this.credentials = { token: 'default', number: 0 };

    Credentials.instance = this;
    return this;
  }

  setCredentials(credentials) {
    this.credentials = credentials;
  }

  getCredentials() {
    return this.credentials;
  }
}

export const setCredentials = credentials => {
  const { token, number } = credentials;
  const storage = new Credentials();
  storage.setCredentials({ token, number });
};

export const getCredentials = () => {
  const storage = new Credentials();
  return storage.getCredentials();
};

export const setOrder = order => {
  const data = order || defaultOrder;
  const { token, number } = getCredentials();
  localStorage.setItem(
    `${types.ORDER}:${token}-${number}`,
    JSON.stringify({ ...data, number })
  );
};

export const getOrder = () => {
  const { token, number } = getCredentials();
  const storage = localStorage.getItem(`${types.ORDER}:${token}-${number}`);
  const order = storage ? JSON.parse(storage) : defaultOrder;

  return { ...order, number };
};

export const setClient = client => {
  localStorage.setItem(`${types.CLIENT}`, JSON.stringify(client));
};

export const getClient = () => {
  const storage = localStorage.getItem(`${types.CLIENT}`);
  const client = storage ? JSON.parse(storage) : defaultClient;

  return client;
};

export const setOrders = orders => {
  const { token } = getCredentials();
  localStorage.setItem(`${types.ORDERS}:${token}`, JSON.stringify(orders));
};

export const getOrders = () => {
  const { token } = getCredentials();
  const storage = localStorage.getItem(`${types.ORDERS}:${token}`);
  const orders = storage ? JSON.parse(storage) : [];
  return orders;
};
