import { makeStyles } from '@material-ui/core/styles';
import backgroundDefault from '../../../assets/background.png';

const height = window.screen.height / 3;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fafafa',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  number: {
    height: '54px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    fontSize: '1.7rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  cover: {
    height: `${height}px`,
    background: `center no-repeat`,
    backgroundImage: props =>
      props.background
        ? `url(${props.background})`
        : `url(${backgroundDefault})`,
    backgroundSize: 'cover',
    padding: '0px',
    border: '0px',
  },
  container: {
    padding: '15px',
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  info: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: '5px',
  },
});

export default useStyles;
